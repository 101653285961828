import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Img from 'gatsby-image';
import anime from 'animejs/lib/anime.es.js';
import SEO from '../components/SEO';
import Facebook from '../assets/images/facebook.inline.svg';
import Instagram from '../assets/images/instagram.inline.svg';
import Info from '../components/Information';
import Card from '../components/Card';

const Header = styled.div`
  pointer-events: auto;
  position: relative;
  max-width: 100rem;
  width: 85%;
  margin: 3rem auto 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  gap: clamp(5rem, 13vw, 10rem);

  ul {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--green-light);
    border-bottom: 1px solid var(--green-light);
    margin-bottom: 3rem;
    padding: 2.5rem 0;

    li:last-child {
      text-align: right;
    }

    span {
      display: block;
      color: var(--green-medium);
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
  }

  svg {
    margin-left: 1rem;
    &:hover {
      path {
        fill: var(--orange);
      }
    }
  }
`;

const Contacts = styled.div`
  align-self: end;
  order: 2;
`;

const Icons = styled.div`
  display: block;
  line-height: 0;
  display: flex;
  justify-content: flex-end;
`;

const Image = styled(Img)`
  display: block;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 800px) {
    margin: -21.5rem auto 0;
    order: 3;
  }
`;

const Logo = styled.h1`
  font-family: 'Source Serif Pro';
  font-size: clamp(4rem, 5vw, 5rem);
  letter-spacing: 0.2em;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 4rem;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  margin-top: clamp(5rem, 13vw, 10rem);

  h2 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.2;
  }
`;

const Container = styled.div`
  max-width: 100rem;
  width: 85%;
  border-top: 1px solid var(--green-light);
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-between;
`;

const SeletedProjects = styled.div`
  max-width: 100rem;
  width: 85%;
  margin: 8rem 0;
`;

const LoopContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  margin-top: clamp(5rem, 13vw, 10rem);
`;

const ServicesLoop = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-left: -2vw;
  white-space: nowrap;
  text-transform: uppercase;
`;

const LoopItem = styled.li`
  font-size: 5.7vw;
  color: var(--green-light);
  font-weight: 500;
  line-height: 120%;
  cursor: none;

  @media (min-width: 800px) {
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--green-medium);
  }

  span {
    padding: 0 2vw;
  }
`;

const ServicesLoopEnd = styled.ul`
  display: flex;
  justify-content: flex-end;
  margin-right: -6.5vw;
  white-space: nowrap;
  text-transform: uppercase;
`;

const HomePage = ({ data }) => {
  const projects = data.projects.nodes;
  const home = data.home.nodes[0].images[0];
  const [offset, setOffset] = useState(0);
  const [animation, setAnimation] = useState();

  useEffect(() => {
    window.onscroll = () => {
      setOffset(
        (window.pageYOffset /
          (document.body.scrollHeight -
            document.documentElement.clientHeight)) *
          100
      );
    };
  }, []);

  useEffect(() => {
    setAnimation(
      anime
        .timeline({
          targets: '.dir-normal',
          translateX: [0, '-20vw'],
          easing: 'easeInQuad',
          autoplay: false,
          opacity: [1, 0],
        })
        .add({
          targets: '.dir-reverse',
          translateX: [0, '20vw'],
          opacity: [1, 0],
        })
    );
  }, [setAnimation]);

  useEffect(() => {
    if (!animation) return;
    animation.seek(animation.duration * (offset / 45));
  }, [offset, animation]);

  const services = [
    'architettura',
    'design',
    'model making',
    'comunicazione grafica',
  ];

  return (
    <>
      <SEO title="Home" />
      <Header>
        <Contacts>
          <Logo>mabastudio</Logo>
          <ul>
            <li>
              <span>E-mail</span>
              <a href="mailto:info@mabastudio.eu">info@mabastudio.eu</a>
            </li>
            <li>
              <span>telefono</span>
              <a href="tel:(+39) 348 4451839">(+39) 348 4451839</a>
            </li>
          </ul>
          <Icons>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mabastudio"
              aria-label="Instagram"
            >
              <Instagram />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Mabastudio-architecture-101603771492116"
              aria-label="Facebook"
            >
              <Facebook />
            </a>
          </Icons>
        </Contacts>
        <Image
          draggable={false}
          fluid={home.image.asset.fluid}
          alt={home.alternativeText}
        />
      </Header>
      <LoopContainer>
        <ServicesLoop className="dir-normal">
          {services.map((service, index) => (
            <LoopItem key={index}>
              {service}
              <span>/</span>
            </LoopItem>
          ))}
        </ServicesLoop>
        <ServicesLoopEnd className="dir-reverse">
          {services.map((service, index) => (
            <LoopItem key={index}>
              {service}
              <span>/</span>
            </LoopItem>
          ))}
        </ServicesLoopEnd>
      </LoopContainer>
      <Section>
        <Container>
          <h2>
            progetti <br /> selezionati
          </h2>
        </Container>
        <SeletedProjects>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 679: 2 }}>
            <Masonry gutter="10rem">
              {projects.map((project) => (
                <Card project={project} key={project.id} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </SeletedProjects>
        <Link className="orange uppercase" to="/architecture">
          tutti progetti
        </Link>
      </Section>
      <Info title="lo studio">
        <p>
          mabastudio, fondato a Bergamo nel 2020, è un team di professionisti
          che opera nel campo della progettazione: architettura, design, model
          making e comunicazione grafica.
        </p>
      </Info>
    </>
  );
};

export default HomePage;

export const query = graphql`
  query HomePage {
    projects: allSanityProject(
      filter: { selected: { eq: true } }
      sort: { fields: year, order: DESC }
    ) {
      nodes {
        year
        name
        id
        category
        selected
        images {
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        slug {
          current
        }
      }
    }

    home: allSanityGallery(filter: { galleryName: { regex: "/home/i" } }) {
      nodes {
        images {
          alternativeText
          image {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
