import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Arrow from '../assets/images/arrow.inline.svg';

const CardStyle = styled(Link)`
  display: block;
`;

const CardDescription = styled.div`
  border-bottom: 1px solid var(--green-light);
  padding: 2.5rem 0;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    li {
      color: var(--green-medium);
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 2;
      letter-spacing: 0.05em;
    }
  }
`;

const CardTitle = styled.div`
  color: var(--green-dark);
  display: flex;
  align-items: center;

  svg {
    margin-left: 1rem;
  }
`;

const CardImg = styled(Img)`
  height: 100%;
  max-height: 70rem;
  min-height: 30rem;
`;

const Card = ({ project }) => (
  <CardStyle to={`/architecture/${project.slug.current}`}>
    <CardImg fluid={project.images[0].image.asset.fluid} alt={project.name} />
    <CardDescription>
      <ul>
        <li>{project.category}</li>
        <li>{project.year}</li>
      </ul>
      <CardTitle>
        <span>{project.name}</span>
        <Arrow />
      </CardTitle>
    </CardDescription>
  </CardStyle>
);

export default Card;
